<template>
  <div v-loading="loading">
    <!-- 学员信息 -->
    <stu-info
      @select="handleStudentSelected"
      :studentId="editDetail.student_id"
    ></stu-info>

    <div class="bg-white margin-top padding">
      <div class="flex margin-bottom">
        <border-title titleName="报名信息" />
        <div class="margin-left">
          <el-button type="primary" size="mini" @click="chooseCourse"
            >选择课程</el-button
          >
        </div>
        <div class="margin-left">
          <el-button type="primary" size="mini" @click="chooseClass()"
            >选择班级</el-button
          >
        </div>
      </div>

      <div
        v-for="(item, index) in Curriculums"
        :key="item.id"
        style="margin-bottom: 20px"
      >
        <div
          class="border-top bg-z padding flex aligin-items-center justify-content-between"
        >
          <div class="flex aligin-items-center">
            <div>
              <template v-show="item.curriculum_name">
                <span class="lesson-tag">课</span>
                <span class="margin-left-sm">{{ item.curriculum_name }}</span>
              </template>
              <template v-show="item.class_name && item.class_id">
                <span class="class-tag margin-left-sm">班</span>
                <span class="margin-left-sm">{{ item.class_name }}</span>
              </template>
              <!-- <span class="margin-left-sm">(刘旭)</span> -->
              <!-- <span class="margin-left-sm">(高升大)</span> -->
            </div>
            <div class="margin-left" v-show="!item.class_id">
              <div class="margin-left">
                <el-button
                  type="primary"
                  size="mini"
                  @click="chooseClass(item.curriculum_id)"
                  >选择班级</el-button
                >
              </div>
            </div>
          </div>

          <div>
            <span class="text-grey text-sm">
              共:
              <span class="text-bold text-black text-normal">{{
                curriculumItemPrice(item).totalNum
              }}</span>
              {{ curriculumItemPrice(item).unit }}
            </span>
            <span class="text-grey margin-left text-sm">
              小计:
              <span class="text-bold text-black text-normal">{{
                item.present_money | moneyFilter
              }}</span>
              元
            </span>
            <i
              class="el-icon-error margin-left-sm text-red cursor"
              @click="delCurriculum(item, index)"
            />
          </div>
        </div>
        <div class="padding border-grey">
          <div class="flex">
            <div class="flex1 border-right-grey">
              <div class="flex aligin-items-center justify-content-between">
                <h6>价格</h6>
                <span class="padding-right">
                  <span class="text-blue text-bold">{{
                    item.total_money | moneyFilter
                  }}</span>
                  <span class="text-grey text-sm">元</span>
                </span>
              </div>
              <div class="padding-tb flex aligin-items-center">
                <span class="text-grey">收费模式</span>
                <div class="margin-left">
                  <el-select
                    v-model="item.courseModel.pay_mode_type"
                    placeholder="请选择"
                    size="mini"
                  >
                    <el-option
                      v-for="charge in item.viewModel.listm"
                      :key="charge.id"
                      :label="chargeDescComputed(charge).title"
                      :value="charge.id"
                    ></el-option>
                  </el-select>
                </div>
                <span class="padding-lr">X</span>
                <div>
                  <el-input-number
                    size="mini"
                    v-model="item.courseModel.pay_num"
                    :min="1"
                  ></el-input-number>
                </div>
              </div>
            </div>

            <div class="flex1 padding-left padding-bottom">
              <div class="flex aligin-items-center justify-content-between">
                <div class="flex">
                  <h6 class="margin-right">优惠</h6>
                  <el-button size="mini" @click="createTextbook(item, index)"
                    >新增优惠</el-button
                  >
                </div>
                <div class="padding-right">
                  <span class="text-green text-bold">{{
                    item | currentItemDiscountFilter
                  }}</span>
                  <span class="text-grey text-sm">元</span>
                </div>
              </div>
              <div class="margin-top-sm">
                <el-select
                  v-model="discountValue"
                  placeholder="请选择"
                  size="mini"
                >
                  <el-option value="" label="营销活动(无)"></el-option>
                </el-select>
              </div>
              <div
                class="margin-top-sm flex aligin-items-center"
                v-for="(discount, discountIndex) in item.discountList"
                :key="discount.type"
              >
                <el-select
                  v-model="discount.type"
                  placeholder="请选择"
                  size="mini"
                >
                  <el-option
                    v-for="discountOption in discountOptionsComputed(
                      item,
                      index,
                      discount
                    )"
                    :key="discountOption.value"
                    :label="discountOption.label"
                    :value="discountOption.value"
                  ></el-option>
                </el-select>
                <div
                  class="flex aligin-items-center"
                  v-if="discount.type === 1"
                >
                  <!--学费折扣-->
                  <el-input-number
                    class="margin-left-sm"
                    size="small"
                    v-model="discount.value"
                    controls-position="right"
                    :step="0.1"
                    :min="0.1"
                    :max="9.9"
                  ></el-input-number>
                  <span class="margin-left-sm">折</span>
                </div>
                <div
                  class="flex aligin-items-center"
                  v-if="discount.type === 2"
                >
                  <!--学费优惠-->
                  <el-input-number
                    class="margin-left-sm"
                    v-model="discount.value"
                    size="small"
                    controls-position="right"
                    :min="0"
                    :max="item.total_money"
                  ></el-input-number>
                  <span class="margin-left-sm">元</span>
                </div>
                <i
                  class="el-icon-error margin-left-sm cursor"
                  @click="delDiscount(item, index, discount, discountIndex)"
                ></i>
              </div>
            </div>
          </div>
          <div class="padding-tb border-top-grey flex aligin-items-center">
            <el-checkbox v-model="item.viewModel.validateTimeDisabled"
              >有效期</el-checkbox
            >
            <div class="margin-left">
              <el-date-picker
                v-model="item.courseModel.valid_time"
                type="date"
                placeholder="选择日期"
                size="mini"
                :disabled="!item.viewModel.validateTimeDisabled"
              />
            </div>
            <div class="margin-left-sm">
              <el-tooltip
                class="item"
                effect="dark"
                content="到达有效期后,剩余课程将无法使用"
                placement="right"
                ><i class="el-icon-question"
              /></el-tooltip>
            </div>
          </div>
          <div
            class="padding-tb border-top-grey flex aligin-items-center justify-content-between"
          >
            <div class="flex aligin-items-center">
              <h6 class="margin-right">教材杂费</h6>
              <el-button size="mini" @click="addTextbook">新建教材</el-button>
            </div>

            <div class="padding-right">
              <span class="text-blue text-bold">0.00</span>
              <span class="text-grey text-sm">元</span>
            </div>
          </div>

          <div
            class="tip border-top-grey padding-top flex aligin-items-center justify-content-between"
          >
            <div class="flex aligin-items-center tip-item flex1">
              <div class="text-grey">对内备注</div>
              <div class="margin-left tip-item-input">
                <el-input
                  v-model="item.order_in_remark"
                  placeholder="该备注只有内部操作人员可见"
                  size="mini"
                />
              </div>
            </div>
            <div class="flex aligin-items-center tip-item flex1">
              <div class="text-grey">对外备注</div>
              <div class="margin-left tip-item-input">
                <el-input
                  v-model="item.order_outer_remak"
                  placeholder="该备注将会被打印且会在校宝家中显示"
                  size="mini"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="no-curriculums" v-show="!Curriculums.length">
        <i class="el-icon-circle-plus-outline"></i>
        <p>请点击左上方按钮选择报名信息</p>
      </div>
    </div>

    <!-- 支付信息 -->
    <pay-info
      :totalPrice="submitForm.order_price"
      :AllBalance="student.balance || 0"
      :handleSchool="editDetail.handle_school"
      :collections="editDetail.collections"
      @change="payInfoChange"
    />
    <!-- 订单标签 -->
    <order-tag @change="orderTagChange" :list="editDetail.order_tags" />
    <!-- 经办信息 -->
    <handle-info
      @change="handleInfoChange"
      :initDatas="editDetail.handleInfo"
    />

    <div class="sign-up-bottom padding">
      <div class="sign-up-bottom-btn">
        <el-button type="primary" size="medium" @click="submit">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import StuInfo from "../components/stuInfo.vue";
import HandleInfo from "../components/handleInfo.vue";
import OrderTag from "../components/orderTag.vue";
import PayInfo from "../components/payInfo.vue";
export default {
  components: {
    StuInfo,
    OrderTag,
    HandleInfo,
    PayInfo,
  },
  data() {
    return {
      id: 0,
      editDetail: {
        student_id: 0,
        handle_school: 0,
        collections: [],
        order_tags: [],
        handleInfo: {},
      },
      loading: false,
      chargingModes: [],
      discountOptions: [
        {
          value: 1,
          label: "学费折扣",
        },
        {
          value: 2,
          label: "学费优惠",
        },
      ],
      discountValue: "",
      submitForm: {
        Curriculums: [],
        sale_source: "",
        handle_date: "",
        order_type: 1,
        student_id: 0,
        //标题，课程名称
        order_title: "",
        //总金额
        order_price: 0,
        order_in_remark: "",
        order_outer_remak: "",
        order_tags: "",
        school_id: 0,
        sale_user: 0,
        //经办校区id
        handle_school: 0,
        //使用余额
        UsedBalance: 0,
        //增加的余额
        IncreaseBalance: 0,
        //学生手机号
        phone: "",
      },
      curriculumItemDefault: {
        actives: [],
        Incidentals: [],
        curriculum_id: "",
        class_id: "",
        //收费模式
        pay_mode_type: "",
        pay_mode: "",
        pay_model_num: 1,
        pay_model_price: 0,
        //购买数量
        pay_num: 1,

        //有效期
        valid_time: "",
        order_type: 1,
        pay_mode_time_slot: 0,
        use_discount: -1,
        use_discount_money: 0,
        //当前项总金额
        total_money: 0,
        //当前项实际应付金额
        present_money: 0,
        //学员id
        student_id: "",
      },
      //报名信息列表
      Curriculums: [],
      //支付信息
      payInfoList: [],
      //当前选择的学员
      student: {},
      chargeTypes: ["", "课时", "时间", "期"],
    };
  },
  filters: {
    currentItemDiscountFilter(item) {
      let discountList = item.discountList;
      if (discountList.length <= 0) {
        return 0;
      }
      let discount = 0;
      discountList.forEach((t) => {
        if (t.type === 1 && t.value > 0) {
          discount += parseInt(((10 - t.value) / 10) * item.total_money);
        }
        if (t.type === 2 && t.value > 0) {
          discount += t.value;
        }
      });
      return Math.min(discount || 0, item.total_money);
    },
  },
  computed: {
    //优惠options
    discountOptionsComputed() {
      return (item, index, discount) => {
        let list = this.discountOptions;
        let discountList = item.discountList;
        let result = [];
        result = list.filter((t) => t.value === discount.type);
        result = result.concat(
          list.filter(
            (t) =>
              t.value !== discount.type &&
              discountList.findIndex((t1) => t1.type === t.value) <= -1
          )
        );
        return result;
      };
    },
    //收费模式下拉框的title
    chargeDescComputed() {
      return (charge) => {
        let charge_type = charge.charge_type;
        let unit = this.chargeTypes[charge_type];
        let title = `${charge.num}${unit} = ${charge.money}元`;
        return {
          title,
        };
      };
    },
    //课时、费用信息
    curriculumItemPrice() {
      return (item) => {
        try {
          let chargeItem = item.viewModel.listm.find(
            (t) => t.id === item.courseModel.pay_mode_type
          );
          chargeItem = chargeItem || item.viewModel.listm[0];
          item.total_money = item.courseModel.pay_num * chargeItem.money;

          let discountTotal = this.calcuPersentPrice(item);
          item.present_money = item.total_money - discountTotal;

          item.courseModel.pay_mode = chargeItem.id;
          return {
            unit: this.chargeTypes[item.courseModel.pay_mode_type],
            totalNum: chargeItem.num * item.courseModel.pay_num,
          };
        } catch (err) {
          return {
            unit: 0,
            totalNum: 0,
          };
        }
      };
    },
  },

  watch: {
    Curriculums: {
      deep: true,
      handler() {
        this.changeTotalPrice();
      },
    },
    "Curriculums.length": {
      handler() {
        this.changeTotalPrice();
      },
    },
  },
  created() {
    let query = this.$route.query;
    this.id = (query && query.id) || 0;
    this.initData();
  },
  methods: {
    calcuPersentPrice(item) {
      if (item.discountList.length <= 0) {
        return 0;
      }
      let discount = 0;
      item.discountList.forEach((t) => {
        if (t.type === 1 && t.value > 0) {
          discount += parseInt(((10 - t.value) / 10) * item.total_money);
        }
        if (t.type === 2 && t.value > 0) {
          discount += t.value;
        }
      });
      return Math.min(discount || 0, item.total_money);
    },
    //初始化编辑的课程信息
    initCurriculumsInfo(id) {
      this.API.getClassDetail(id).then(({ data }) => {
        if (data && data.id) {
          let index = this.Curriculums.findIndex(
            (t) => t.curriculum_id === id && !t.class_id
          );
          if (index < 0) {
            return;
          }
          let curriculumsItem = this.Curriculums[index];
          this.Curriculums[index] = Object.assign({}, this.Curriculums[index], {
            curriculum_name: data.curriculum_name,
            classModel: {},
            actives: [],
            discountList: [],
            courseModel: Object.assign({},curriculumsItem.courseModel,{listm: data.listm || [],}, data),
            viewModel: {
              validateTimeDisabled: false,
              listm: data.listm || [],
            },
          });
          console.log("data.listm", data.listm);
          console.log(
            "curriculumsItem.courseModel.pay_mode_type",
            curriculumsItem.courseModel.pay_mode_type
          );
          console.log(
            "curriculumsItem.courseModel.pay_mode_type",
            curriculumsItem.viewModel.listm
          );
          this.Curriculums = JSON.parse(JSON.stringify(this.Curriculums));
          console.log("initCurriculumsInfo this.Curriculums", this.Curriculums);
        }
      });
    },
    //初始化编辑的班级信息
    initClassInfo(id) {
      this.API.ClassesDetail(id).then(({ data }) => {
        if (data && data.id) {
          let index = this.Curriculums.findIndex((t) => t.class_id === id);
          if (index < 0) {
            return;
          }
          this.Curriculums[index] = Object.assign({}, this.Curriculums[index], {
            actives: [],
            Incidentals: [],
            class_id: data.id,
            classModel: data,
            curriculum_id: data.curriculum.id,
            discountList: [],
            courseModel: {
              ...this.curriculumItemDefault,
              ...data.curriculum,
              pay_mode_type: data.curriculum.listm[0].charge_type,
              pay_mode: data.curriculum.listm[0].id,
              listm: data.curriculum.listm,
            },
            curriculum_name: "",
            viewModel: {
              validateTimeDisabled: false,
              listm: data.curriculum.listm,
            },
          });
          this.Curriculums = JSON.parse(JSON.stringify(this.Curriculums));
          console.log("initClassInfo this.Curriculums", this.Curriculums);
        }
      });
    },
    initData() {
      if (this.id) {
        this.getOrderDetail(this.id).then(({ data }) => {
          if (data && data.id) {
            this.submitForm = Object.assign({}, this.submitForm, data);

            //学员信息
            this.editDetail.student_id = data.student_id;

            //报名信息
            this.Curriculums = this.submitForm.Curriculums.map((item) => {
              if (item.class_id) {
                //班级
                this.initClassInfo(item.class_id);
                return {
                  actives: [],
                  Incidentals: [],
                  class_id: item.class_id,
                  classModel: item,
                  curriculum_id: item.curriculum_id,
                  discountList: [],
                  courseModel: Object.assign(
                    { ...this.curriculumItemDefault },
                    {
                      pay_num: 1,
                      valid_time: "",
                      pay_mode_type: Number(item.pay_mode),
                      pay_mode: Number(item.pay_mode),
                      listm: [],
                    }
                  ),
                  curriculum_name: "",
                  viewModel: {
                    validateTimeDisabled: false,
                    listm: [],
                  },
                };
              } else {
                //课程
                this.initCurriculumsInfo(item.curriculum_id);
                return {
                  curriculum_id: item.curriculum_id,
                  classModel: {},
                  actives: [],
                  discountList: [],
                  courseModel: Object.assign(
                    { ...this.curriculumItemDefault },
                    item,
                    {
                      pay_num: 1,
                      valid_time: "",
                      pay_mode_type: item.pay_mode.toString(),
                      pay_mode: item.pay_mode.toString(),
                    listm: [],
                      
                    }
                  ),
                  viewModel: {
                    validateTimeDisabled: false,
                    listm: [],
                  },
                };
              }
            });

            //支付信息
            this.editDetail.handle_school = data.handle_school;
            this.editDetail.collections = JSON.parse(
              JSON.stringify(data.collections || [])
            );

            //订单标签
            this.editDetail.order_tags = data.order_tags
              ? data.order_tags.split(",")
              : [];

            //经办信息
            this.editDetail.handleInfo = {
              sale_source: data.sale_source,
              handle_date: data.handle_date,
              sale_user: data.sale_user,
              handle_user: data.handle_user,
            };
            this.Curriculums = JSON.parse(JSON.stringify(this.Curriculums));
            console.log("initData this.Curriculums", this.Curriculums);
          }
        });
      }
      this.API.SystemGetSettings("ChargingMode").then((res) => {
        this.chargingModes = res || [];
      });
    },
    getOrderDetail(id) {
      return this.API.getOrderDetailEdit(id);
    },
    changeTotalPrice() {
      setTimeout(() => {
        this.submitForm.order_price = this.Curriculums.reduce(
          (total, currentValue) => {
            let count = currentValue.present_money || 0;
            return total + count;
          },
          0
        );
      }, 1);
    },
    submit() {
      let submitForm = {
        ...this.submitForm,
        Curriculums: [],
        collections: this.payInfoList,
      };
      let msg = "";
      if (!this.Curriculums.length) {
        this.$message.error("报名信息不能为空");
        return;
      }
      if (submitForm.student_id <= 0) {
        this.$message.error("请选择学员");
        return;
      }
      let curriculumItemDefault = this.curriculumItemDefault;

      submitForm.Curriculums = this.Curriculums.map((t) => {
        let obj = {
          student_id: submitForm.student_id,
          class_id: t.class_id,
          pay_mode: t.courseModel.listm.find(
            (item) => item.id === t.courseModel.pay_mode_type
          ).id,
        };
        //优惠信息
        if (t.discountList.length) {
          t.discountList.forEach((discount) => {
            if (discount.type === 1 && discount.value > 0) {
              obj.use_discount = discount.value / 10;
            }
            if (discount.type === 2 && discount.value > 0) {
              obj.use_discount_money = discount.value;
            }
          });
        }
        for (const key in curriculumItemDefault) {
          if (Object.hasOwnProperty.call(curriculumItemDefault, key)) {
            obj[key] =
              obj[key] ||
              t.courseModel[key] ||
              t[key] ||
              curriculumItemDefault[key];
          }
        }
        return obj;
      });
      if (msg) {
        this.$message.error(msg);
        return;
      }
      this.loading = true;
      this.API.postAddOrder(submitForm)
        .then((res) => {
          if (res.success) {
            this.$message.success("下单成功");
            this.$router.go(-1);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    payInfoChange(payInfoList) {
      let {
        payList,
        handle_school,
        IncreaseBalance,
        UsedBalance,
      } = payInfoList;
      this.payInfoList = payList;
      this.submitForm = Object.assign(this.submitForm, {
        handle_school,
        IncreaseBalance,
        UsedBalance,
      });
    },
    orderTagChange(val) {
      this.submitForm.order_tags = val.join(",");
    },
    handleInfoChange(val) {
      let { sale_source, handle_date, sale_user, handle_user } = val;
      this.submitForm = Object.assign(this.submitForm, {
        sale_source,
        handle_date,
        sale_user,
        handle_user,
      });
    },
    //删除当前报名信息
    delCurriculum(item, index) {
      this.Curriculums.splice(index, 1);
    },
    //选择了学员
    handleStudentSelected(student) {
      if (student && student.id) {
        this.submitForm.student_id = student.id;
        this.submitForm.phone = student.phone;
        this.student = student;
      }
    },
    // 选择课程
    chooseCourse() {
      this.$Dialog.ChooseCourses().then((res) => {
        res.forEach((item) => {
          this.Curriculums.unshift({
            curriculum_name: item.curriculum_name,
            curriculum_id: item.id,
            classModel: {},
            actives: [],
            discountList: [],
            courseModel: Object.assign(
              {
                ...this.curriculumItemDefault,
                pay_num: 1,
                valid_time: "",
                pay_mode_type: item.listm[0].id,
                pay_mode: item.listm[0].id,
                listm: item.listm,
              },
              item
            ),
            viewModel: {
              validateTimeDisabled: false,
              listm: item.listm,
            },
          });
        });
      });
    },
    // 选择班级
    chooseClass(courseId) {
      let courseIds = (courseId && [courseId]) || [];
      this.$Dialog.ChooseClass({ courseId: courseIds }).then((res) => {
        if (!res || !res.length) {
          return;
        }
        if (courseId) {
          let classModel = res[0];
          let index = this.Curriculums.findIndex(
            (t) => t.curriculum_id == courseId
          );
          this.Curriculums[index].classModel = classModel;
          this.Curriculums[index].class_id = classModel.id;
        } else {
          res.forEach((item) => {
            this.Curriculums.unshift({
              actives: [],
              Incidentals: [],
              class_id: item.id,
              class_name: item.class_name,
              classModel: item,
              curriculum_id: item.curriculum.id,
              discountList: [],
              courseModel: {
                ...this.curriculumItemDefault,
                ...item.curriculum,
                pay_mode_type: item.curriculum.listm[0].charge_type,
                pay_mode: item.curriculum.listm[0].id,
                listm: item.curriculum.listm,
              },
              curriculum_name:
                item.curriculum && item.curriculum.curriculum_name,
              viewModel: {
                validateTimeDisabled: false,
                listm: item.curriculum.listm,
              },
            });
          });
        }
        this.Curriculums.push(this.Curriculums.pop());
      });
    },

    // 新建教材
    addTextbook() {
      this.$Dialog.AddTextbook().then((res) => {
        console.log(res);
      });
    },

    // 新增账户
    addPayInfo() {
      let arr = {
        padInfoValue: 0,
        realInput: "0",
        padInfoOptions: [
          {
            value: 0,
            label: "现金",
            padInfoValue: "",
            realInput: "59.8",
          },
          {
            value: 1,
            label: "刷卡",
            padInfoValue: "",
            realInput: "0",
          },
          {
            value: 2,
            label: "支付宝",
            padInfoValue: "",
            realInput: "0",
          },
          {
            value: 3,
            label: "微信",
            padInfoValue: "",
            realInput: "0",
          },
        ],
      };
      this.payList.map((t) => {
        arr.padInfoOptions.map((j, k) => {
          if (t.padInfoValue == j.value) arr.padInfoOptions.splice(k, 1);
        });
      });
      arr.padInfoValue = arr.padInfoOptions[0].value;
      this.payList.push(arr);
    },
    // 删除账户
    delPayInfo(index) {
      this.payList.splice(index, 1);
    },

    //删除优惠
    delDiscount(item, index, disacount, discountIndex) {
      this.Curriculums[index].discountList.splice(discountIndex, 1);
    },
    // 新增优惠
    createTextbook(item, index) {
      if (item.discountList.length >= this.discountOptions.length) {
        return;
      }
      let discount = this.discountOptions.find(
        (t) => item.discountList.findIndex((t1) => t1.type === t.value) <= -1
      );
      this.Curriculums[index].discountList.push({
        type: discount.value,
        val: 0,
      });
    },
  },
};
</script>

<style scoped>
>>> .el-select .el-input {
  width: 160px;
}
>>> .el-input-number .el-input {
  width: 130px;
}
>>> .el-date-editor.el-input {
  width: 150px;
}

.tip {
  width: 100%;
}
.tip-item {
  width: 100%;
}
.tip-item-input {
  width: 85%;
}
.el-icon-question:hover {
  color: #409eff;
}
.sign-up-bottom {
  bottom: 0;
  right: 0;
  position: fixed;
}
</style>
<style lang="scss" scoped>
.no-curriculums {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ccc;
}
</style>
